import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
  useIonViewWillEnter
} from '@ionic/react';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import { getMeDetails, getProfAffiliate, setAsAuthenticated } from '../redux/actions/authActions';
import { useLocation, useParams } from 'react-router';
import logo from '../assets/imgs/logo.svg'
import Step3 from '../components/Step3';

const Step2Page = (props) => {
  const router = useIonRouter()

  const refresh = (e) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <img src={logo} className='logo' alt='logo' />

        <Step2 next={() => router.push('/step-3')} />
        
      </IonContent>
    </IonPage>
  );
};

export default Step2Page;
