import React, { useEffect, useState } from 'react';

import {
  IonContent,
  IonHeader,
  IonList,
  IonInput,
  IonPage,
  IonButton,
  IonCol,
  IonRow,
  IonTitle,
  useIonRouter,
  IonText,
  useIonViewWillEnter
} from '@ionic/react';
import './Login.css';
import './Gift.css';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAffiliate, getMeDetails, setAsAuthenticated } from '../redux/actions/authActions';
import logo from '../assets/imgs/logo.svg'
import { APP_URL } from '../redux/actions/api';
import thank_all from '../assets/imgs/thank_all.svg'
import {Helmet} from "react-helmet";


const Gift = () => {
  const router = useIonRouter()
  const { user, isAuthenticated, otp } = useSelector((state = {}) => state.auth);
  const { loading, liveActions } = useSelector((state = {}) => state.async);

  const dispatch = useDispatch()

  useIonViewWillEnter(() => {
    if (!localStorage.getItem('AuthToken')) {
      router.push('/home')
    }

    if(!liveActions.includes('aff') && localStorage.getItem('AuthToken') && user && !user?.affiliate_url) {
      dispatch(CreateAffiliate())
      
    }

    if (!liveActions.includes('me') && !isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())

    }
  })

  const ShareClick = () => {
    const url = user.short_aff_link ? user.short_aff_link : `${APP_URL}home/?source=${user.affiliate_url}`;
    window.open(`https://wa.me/?text=` + `דוקטוריטה -  בא לך להמליץ ולצאת לחופשה? ` + '%0a' + url,'_blank')
  }


  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <img src={logo} className='logo' alt='logo' />

        <IonRow>
          <IonCol>
            <p className='connexionTitle'>
              מגדילים ביחד את מעגל הפירגון וההמלצות 
            <br/>
            ומשתפים כמה שיותר חברים וחברות!
            </p>
            <p className='sub-desc desc-thx'>
            קליק קטן לשיתוף יגדיל את סיכוייך לזכות בחופשה
            </p>


          </IonCol>
        </IonRow>

        <IonRow className='next-btn-row gift-row'>
          <IonCol>
            {!!user?.affiliate_url &&
               <IonButton className='next-btn' onClick={ShareClick} >
                     אשמח לשתף
              
              </IonButton>
              
            }
          </IonCol>
        </IonRow>

      </IonContent>
    </IonPage>
  );
};

export default Gift;
