import {SET_NAVBAR_CURRENT_ITEM, SET_EXPPANDED_NAV, SET_ITEMS} from '../actions/types'

const initialState = {
  selectedIndexInNavBar: 0,
  expanded:  !!Number(localStorage.getItem('expandedNavBar')),
  items: [],
}


const navbarReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {    
    case SET_NAVBAR_CURRENT_ITEM:
      localStorage.setItem('selectedIndexInNavBar', payload)
      return {
        ...state,
        selectedIndexInNavBar: parseInt(payload)
      }
    case SET_EXPPANDED_NAV:
       localStorage.setItem('expandedNavBar', payload ? 1 : 0)
      return {
        ...state,
        expanded: payload
      }
    case SET_ITEMS:
    
      return {
        ...state,
        items: payload      
      } 
    default:
      return state
  }
}

export default navbarReducer
