import axios from 'axios'

const setupAxios = (accessToken, formFunc) => {
  // ** Request Interceptor
  
  axios.interceptors.request.use(
    config => {
      if (accessToken && formFunc) {
      //  config.headers.common['Authorization'] = `Bearer ${localStorage.getItem("AuthToken")}`;//accessToken}`
        config.headers.Authorization = `Bearer ${localStorage.getItem("AuthToken")}`
      } else {
        delete axios.defaults.headers.common['Authorization']
      }

      return config
    },
    error => Promise.reject(error)
  )

  


}

const setAuthToken = accessToken => {
  setupAxios(accessToken, true)
}

export default setAuthToken
