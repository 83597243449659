import {OPEN_MODAL, CLOSE_MODAL} from '../actions/types'

const initialState = {
  modal_name: null,
}

const modalReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {    
    case OPEN_MODAL:
     
      return {
        ...state,
        modal_name: payload
      }
    case CLOSE_MODAL:
      return {
        ...state,
        modal_name: null
      } 
    default:
      return state
  }
}

export default modalReducer
