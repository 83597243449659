import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonSearchbar,
  IonTextarea,
  IonCol,
  IonRow,
  IonToolbar,
  IonItem,
  IonIcon,
  useIonRouter,
  IonLoading,
  useIonViewDidEnter
} from '@ionic/react';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import { getMeDetails, setAsAuthenticated } from '../redux/actions/authActions';
import logo from '../assets/imgs/logo.svg'
import flag from '../assets/imgs/flag.svg'
import spinner from '../assets/imgs/spinner.png'
import thank_1 from '../assets/imgs/thank_1.svg';
import thank_all from '../assets/imgs/thank_all.svg'
import './Recommendations.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import Recommendations from './Recommendations';

const Thank = (props : any) => {
  const { user, isAuthenticated } = useSelector((state = {}) => state.auth);
  const { loading } = useSelector((state = {}) => state.async);
  const dispatch = useDispatch()
  const router = useIonRouter();


  useEffect(() => {
    if (!localStorage.getItem('AuthToken')) {
      router.push('/home')
    }

    if (!isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
    }
  },[]);

  useEffect(() => {
    if(!localStorage.getItem('AuthToken')) {
      router.push('/home')
    }
  }, [loading])


  if(loading || !user) {
    return <IonLoading isOpen={loading} />
  }

  const Next = () => {
    if(props.nextFunc && user?.rec_count < 3) {
      props.nextFunc()
    } else {
      if(user?.rec_count >= 3) {
        window?.dataLayer?.push({
            event: "GTM event to GA",
            GA_event_category: "Doctorita",
            GA_event_action: "Doctorita_share_click",
            GA_event_label: 'Doctorita_share_click'
          });
                        
      }
      router.push(user?.rec_count < 3 ? '/recommendations' : '/finish')
    }
  }

  return (
      <IonContent fullscreen>
        <img src={logo} className='logo' alt='logo' />

        {loading  ? <IonLoading isOpen={loading} /> : <>
      
      {!!user && 
        <IonRow className='stepper-row'>
          <IonCol className='stepper-col' size='9'>
            <Stepper activeStep={user.rec_count || 0}>
              {[1, 2, 3].map((label, index) => <Step key={label}>
                <StepLabel></StepLabel>
              </Step>)}
            </Stepper>

          </IonCol>
          <IonCol className='flag-col' size='3'>
            <span className={`flag-line ${user?.rec_count < 3 ? "" : "active-line"}`}></span>
            {user?.rec_count < 3 ?
              '' : <img src={spinner} alt="spinner" className='spinner-img' />}
            <img src={flag} alt="flag" className='flag-img' />
          </IonCol>
        </IonRow>
        }

          <IonRow>
            <IonCol>
              <img src={user?.rec_count < 3 ? thank_1 : thank_all} className={user?.rec_count < 3 ? '' : 'thank_all'} alt='thank' />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {user?.rec_count < 3 ? <>
                <h4 className='desc thank-txt'>תודה רבה על ההמלצה!</h4>
                <p className='sub-desc desc-thx'>נשארו לך רק עוד {user?.rec_count ? (3 - user?.rec_count) : '3'} המלצות וזהו.</p>
              </> :
                <>
                  <h4 className='desc thank-txt end'>
                  תודה מכל הלב על ההמלצות!
                  </h4>
                  <p className='sub-desc desc-thx'>
                  עכשיו תורנו לפרגן לך בחזרה 
                  ולהכניס אותך למשחק עם פרס לחופשה מפנקת במלון שווה!
                  </p>
                  <br />
                  <p className='final-note'>
                  מבטיחים לשלוח לך בקרוב הודעה על השקת האפליקציה
שתעזור לך למצוא בקלות רופאים/ות ומטפלים/ות 
שהומלצו ע&ldquo;י החברים והחברות שלך.
</p>
                </>}
            </IonCol>
          </IonRow>

             
          <IonRow className={'next-btn-row ' + user?.rec_count < 3 ? '' : 'end-btn'}>
            <IonCol>
                <IonButton onClick={Next} className='next-btn' style={{ width: '85%' }} shape="round">
                {user?.rec_count < 3 ? 'להמלצה נוספת' : 'ועוד משהו קטן לסיום'}
              </IonButton>
            </IonCol>
          </IonRow>
        </>}

      </IonContent>
  );
};

export default Thank;
