import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
  useIonViewWillEnter
} from '@ionic/react';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import { getMeDetails, getProfAffiliate, setAsAuthenticated } from '../redux/actions/authActions';
import { useLocation, useParams } from 'react-router';
import logo from '../assets/imgs/logo.svg'
import Step3 from '../components/Step3';

const Home = (props) => {
  const router = useIonRouter()
  const { user, isAuthenticated, prof_affiliate } = useSelector((state = {}) => state.auth);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch()
  const search = useLocation().search

  useIonViewWillEnter(() => {
    let params = new URLSearchParams(search);
    
    if (params.get('source')) {
      localStorage.setItem('source', params.get('source'));
      dispatch(getProfAffiliate(params.get('source')))
    }

    if (!isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
    }
  });

  useEffect(() => {
    if (prof_affiliate && !isAuthenticated) {
      router.push('/login')
    }

  }, [prof_affiliate])

  

  const refresh = (e) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  const Next = () => {
    router.push(!isAuthenticated ? '/login' : '/recommendations')
    window?.dataLayer?.push({
      event: "GTM event to GA",
      GA_event_category: "Doctorita",
      GA_event_action: "Doctorita_click_start",
      GA_event_label: 'Doctorita_click_start'
    });
  }

  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <img src={logo} className='logo' alt='logo' />

        <Step1 next={Next} />
        {/*step == 1 && }
        {step == 2 && <Step2 next={() => router.push('/step-3')} />}
        {step == 3 && <Step3 />*/}

      </IonContent>
    </IonPage>
  );
};

export default Home;
