import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {logoWhatsapp } from 'ionicons/icons';

import Home from './pages/Home';
import { Provider } from 'react-redux'; //Add to line 1
import store from './redux/store/store'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import Recommendations from './pages/Recommendations';
import FirstPage from './pages/FirstPage';
import Thank from './pages/Thank';
import Gift from './pages/Gift';
import Step2Page from './pages/Step2Page';
import Step3Page from './pages/Step3Page';
import { createAnimation } from '@ionic/react';
import Name from './pages/Name';
import Privacy from './pages/Privacy';
import Trems from './pages/Terms';

setupIonicReact();

const animationBuilder = (baseEl : any, opts: any) => {

  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo(
      'transform',
      'translateX(100%)',
  
      'translateX(0)'
    )
   // .direction('reverse')
  .duration(250);
  
  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo(
      'transform',
      'translateX(100%)',
  
      'translateX(0)'
    )
    .direction('reverse')
  .duration(350);
  
  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);
    
  return animation;
};

const App: React.FC = () =>  (
  <Provider store={store}> 
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet animation={animationBuilder}>
        <Route path="/" exact={true}>
          <Redirect to="/home" />
        </Route>
        <Route path="/home" exact={true}>
          <Home />
        </Route>
        <Route path="/step-2" exact={true}>
          <Step2Page />
        </Route>
        <Route path="/step-3" exact={true}>
          <Step3Page />
        </Route>
        
        <Route path="/recommendations" exact={true}>
          <Recommendations />
        </Route>
        <Route path="/first-page" exact={true}>
          <FirstPage />
        </Route>
        <Route path="/login" exact={true}>
           <Login />
        </Route>
        <Route path="/thank" exact={true}>
           <Thank nextFunc={false}/>
        </Route>
        <Route path="/finish" exact={true}>
           <Gift />
        </Route>
        
        <Route path="/name" exact={true}>
           <Name/>
        </Route>
        <Route path="/privacy" exact={true}>
          <Privacy />   
        </Route>
        <Route path='/terms-of-use' exact={true}>
          <Trems/> 
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
    <IonFab  slot="fixed" vertical="bottom" horizontal='end' >
      <IonFabButton color="success" size="small" 
        onClick={() => window.open('https://wa.me/972542178628?','_blank')}>
       <IonIcon icon={logoWhatsapp}></IonIcon>
      </IonFabButton>
    </IonFab>
  </IonApp>
  </Provider>
)

export default App;
