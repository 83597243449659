import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonInput,
  IonPage,
  IonButton,
  IonCol,
  IonRow,
  useIonRouter,
  IonCheckbox,
  IonLoading
} from '@ionic/react';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { SendName, login, restartOtp, sendOtp } from '../redux/actions/authActions';
import logo from '../assets/imgs/logo.svg'
import { useLocation } from 'react-router';

const Name = () => {
  const router = useIonRouter()
  const { user, isAuthenticated, otp, prof_affiliate } = useSelector((state = {}) => state.auth);
  const { loading } = useSelector((state = {}) => state.async);
 
  const [name, setName] = useState(user?.first_name ? (user?.first_name + ' ' + user?.last_name) : '');
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if(location.pathname == '/name') {

    if(!localStorage.getItem('AuthToken')) {
      router.push('/home')
    } else if(user?.last_name) {
      router.push('/recommendations')
    }
    }
  }, [])

  useEffect(() => {
    if(location.pathname == '/name') {
      if( user?.rec_count >= 3) {
        router.push('/recommendations')
        return;
      }
      if(user?.last_name) {
        router.push('/recommendations')
        return;
      }
    }
  }, [user])


  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    window?.dataLayer?.push({
      event: "GTM event to GA",
      GA_event_category: "Doctorita",
      GA_event_action: "Doctorita_enter_name",
      GA_event_label: 'Doctorita_enter_name'
    });
      dispatch(SendName(name));
      router.push('/recommendations')
  };


  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && name.length > 1) {
      handleSubmit()
    }
  }

  return (
    <IonPage id="login-page">
      <IonContent fullscreen>
        <img src={logo} className='logo' alt='logo' />

        <IonRow>
            <IonCol>
              <p className='connexionTitle'>
               <span>שלום , איך קוראים לך?</span>
              </p>
              </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
              <IonInput className='tel-input' value={name} 
              onIonInput={(event) => setName(event.target.value)}
                required placeholder='שם מלא'
                onKeyUp={handleKeyUp}>
              </IonInput>
           </IonCol>
        </IonRow>
        
        <IonRow>
          <IonCol>
            {!!name && name.length > 1 &&
            <IonButton disabled={loading}  onClick={handleSubmit} className='next-btn send-btn' shape="round" style={{ width: '80%' }}>
              אישור
            </IonButton>}
            <IonLoading isOpen={loading} />
          </IonCol>
        </IonRow>

      </IonContent>
    </IonPage>
  );
};

export default Name;
