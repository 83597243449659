import React from 'react';
import {
  IonButton,
  IonCol,
  IonRow
} from '@ionic/react';
import './Step.css'
import { Link } from 'react-router-dom';
import './Step.css'
import step2 from '../assets/imgs/step2.svg';
import MobileStepper from '@mui/material/MobileStepper';

interface StepProps {
  next?: (e: any) => void,
}


const Step2 = ({ next }: StepProps) => {
  return (
    <div className="step-content">
      <img src={step2} alt='step 2' className='step-img' />
      <IonRow>
        <IonCol>
          <MobileStepper
            className='stepper'
            variant="dots"
            steps={3}
            position="static"
            activeStep={1} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h3 className='title'>לפרגן לאנשי מקצוע</h3>
          <p className='desc'>מכירים בעלי מקצוע טובים?<br />
            רוצים שהחברים שלכם גם ידעו?<br />
            כי מי שעובד טוב, מגיע לו שיפרגנו לו.
          </p>
        </IonCol>
      </IonRow>
      <IonRow className='next-btn-row' style={{marginTop : window.innerHeight < 750 ? '20px' : '50px'}}>
        {/* <IonCol>
          <IonButton color="dark" className='jump-btn' fill="clear" onClick={() => next()}>
            דלג
          </IonButton>
        </IonCol> */}
        <IonCol>
          <IonButton className='next-btn' shape="round" onClick={() => next()}>
            הבא
          </IonButton>
        </IonCol>
      </IonRow>

    </div>);
};

export default Step2;
