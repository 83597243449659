import axios from 'axios'
import {asyncActionStart, asyncActionFinish, asyncActionError} from './asyncActions'
import setAuthToken from '../../auth/setAuthToken'
import {
  LOGIN_FAIL,
  SET_AS_AUTHENTICATED,
  LOGOUT,
  USER_DATA,
  OTP,
  RESTART_OTP,
  PROF_AFFILIATE,
  SETTINGS
} from './types'

import { API_URL } from './api';


export const getSettings = () => async dispatch => {
  dispatch(asyncActionStart())

  try {
    const resSettings = await axios.get(`${API_URL}auth/settings/`)
    dispatch({
      type: SETTINGS,
      payload: resSettings.data?.data
    })
    dispatch(asyncActionFinish())
  } catch (e) {
    dispatch(asyncActionFinish())
  }
}
// Login User
// export const sendOtp=(data)=> 
export const sendOtp = (formData) => async dispatch => {
  dispatch(asyncActionStart())

  try {
    const resSettings = await axios.get(`${API_URL}auth/settings/`)
    dispatch({
      type: SETTINGS,
      payload: resSettings.data?.data
    })
    
    const url = `${API_URL}auth/login/`
    setTimeout(() => {
      dispatch({
        type: OTP,
      })
      dispatch(asyncActionFinish())
    }, 100);
    const res = await axios.post(url, formData)
    if(res.data.error) {
      dispatch({
        type: RESTART_OTP
        })
       
        dispatch(asyncActionError(res.data.error))
    }
    else {
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {
    console.log(error)
    dispatch({
    type: RESTART_OTP
    })
    if (error.response && error.response.data && error.response.data.message) {
      
      dispatch(asyncActionError(error.response.data.message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const login = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${API_URL}auth/verify-code`

    const res = await axios.post(url, formData)
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    }
    else if(res.data.data?.success){
      
        dispatch({
            type: USER_DATA,
            payload: res.data.data?.user_detail
          })
          dispatch(setAsAuthenticated(res.data.data.access_token))
      
       
    } else {
      
      dispatch({
        type: LOGIN_FAIL
      })

        
    }
    dispatch(asyncActionFinish())
   
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL
    })
    if (error.response && error.response.data && error.response.data.message) {
      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const setAsAuthenticated = authToken => {
  localStorage.setItem("AuthToken", authToken);
  setAuthToken(authToken)
  return {type: SET_AS_AUTHENTICATED, payload: authToken}
}

export const saveRec = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${API_URL}member/recommendation`

    const res = await axios.post(url, formData)
    if(res.data.data?.error || !res.data.data?.status) {
        dispatch(asyncActionError(res.data.data.error))
    }
    else {
      dispatch({
        type: USER_DATA,
        payload: res.data.data.member
      })
   
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {
  
         dispatch(asyncActionError(error.response.data))

    if (error.response && error.response.data && error.response.data.message) {
      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const SendName = (name) => async dispatch => {
  dispatch(asyncActionStart())
  try {
  const res = await axios.post(`${API_URL}member/name`, {name : name})
    if(res.data.error) {
        dispatch(asyncActionError(res.data.error))
    }
    else {
        dispatch({
            type: USER_DATA,
            payload: res.data.data
          })
    }
    dispatch(asyncActionFinish())
   
  } catch (error) {    
  
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      if(error.response.status == 401) {
          dispatch({
            type: LOGOUT,
        })
        dispatch(asyncActionError(''))
      } 
    }
  }
}


export const CreateAffiliate = () => async dispatch => {
  dispatch(asyncActionStart('aff'))
  try {
  const res = await axios.post(`${API_URL}member/affiliate`)
    if(res.data.error) {
        dispatch(asyncActionError(res.data.error))
    }
    else {
        dispatch({
            type: USER_DATA,
            payload: res.data.data
          })
    }
    dispatch(asyncActionFinish())
   
  } catch (error) {    
  
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      if(error.response.status == 401) {
          dispatch({
            type: LOGOUT,
        })
        dispatch(asyncActionError(''))
      } 
    }
  }
}

export const getProfAffiliate = (affiliate) => async dispatch => {
  dispatch(asyncActionStart())
  try {
  const res = await axios.get(`${API_URL}professional/affiliate?id=${affiliate}`)
    if(res.data.error) {
        dispatch(asyncActionError(res.data.error))
    }
    else {
        dispatch({
            type: PROF_AFFILIATE,
            payload: res.data.data
          })
    }
    dispatch(asyncActionFinish())
   
  } catch (error) {      
    if (error.response && error.response.data && error.response.data.title) {
      dispatch(asyncActionError(error.response.data))
    } else {
        dispatch(asyncActionError(''))
      
    }
  }
}


export const getMeDetails = () => async dispatch => {
  dispatch(asyncActionStart('me'))
  try {
  const res = await axios.get(`${API_URL}member/profile`)
    if(res.data.error) {
        dispatch(asyncActionError(res.data.error))
    }
    else {
        dispatch({
            type: USER_DATA,
            payload: res.data.data.user_detail
          })
    }
    dispatch(asyncActionFinish())
   
  } catch (error) {    
  
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      if(error.response.status == 401) {
          dispatch({
            type: LOGOUT,
        })
        dispatch(asyncActionError(''))
      } 
    }
  }
}
export const restartOtp=()=>{
  return{
    type: RESTART_OTP,
  }
}
// Logout
export const logout = () => {
  setAuthToken(false)
  return {
    type: LOGOUT
  }
}

