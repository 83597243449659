import {combineReducers} from 'redux'
import authReducer from './authReducer'
import navbarReducer from './navbarReducer'
import asyncReducer from './asyncReducer'
import modalReducer from './modalReducer'



const rootReducer = combineReducers({
  auth: authReducer,
  navbar: navbarReducer,
  async: asyncReducer,
  modal: modalReducer,

})

export default rootReducer
