import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonSearchbar,
  IonTextarea,
  IonCol,
  IonRow,
  IonToolbar,
  IonItem,
  IonIcon,
  useIonRouter,
  IonCard,
  IonText,
  IonButtons,
  IonModal,
  IonGrid,
  IonTitle,
  useIonViewDidEnter,
  IonInput
} from '@ionic/react';
import './Recommendations.css';
import { useDispatch, useSelector } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { chevronBackOutline, chevronForwardOutline, closeOutline, personOutline, searchSharp } from 'ionicons/icons';
import { getMeDetails, saveRec, setAsAuthenticated } from '../redux/actions/authActions';
import { camera, film, flash, home } from 'ionicons/icons';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import logo from '../assets/imgs/logo.svg';
import heart from '../assets/imgs/big_heart.png'

import flag from '../assets/imgs/flag.svg'
import love_selected from '../assets/imgs/love_selected.svg'
import { StepLabel } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../redux/actions/api';
import Thank from './Thank';
import {Helmet} from "react-helmet";


const Recommendations = (props) => {

  const dispatch = useDispatch();
  const { user, isAuthenticated, prof_affiliate } = useSelector((state = {}) => state.auth);
  const { loading, liveActions } = useSelector((state = {}) => state.async);

  const router = useIonRouter();
  const [results, setResults] = useState([]);
  const [currentPro, setCurretnPro] = useState(null)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [rec, setRec] = useState('')
  const [searchText, setSearchText] = useState(null)
  const [profMobile, setProfMobile] = useState(null)
  const [sent, setSent] = useState(false);
  const categories = [
    {
      name: 'יילוד וגניקולוגיה',
      id: 5
    },
    {
      name: 'רפואת שיניים',
      id: 4,
    },
    {
      name: 'אף אוזן גרון',
      id: 6
    },
    {
      name: 'עור',
      id: 85
    },
    {
      name: 'ילדים',
      id: 72
    },
    {
      name: 'משפחה',
      id: 79
    },
    {
      name: 'פסיכולוגיה',
      id: 1
    },
    {
      name: 'אורתופדיה',
      id: 77
    },
    {
      name: 'פיזיותרפיה',
      id: 2
    }, 
    {
      name: 'קלינאות תקשורת',
      id: 3
    },
    
    {
      name: 'עיניים',
      id: 91
    },
    /*{
      name: 'קוסמטיקאיות',
      id: 8
    }*/

  ]

  const searchInputRef = useRef()

  useIonViewDidEnter(() => {
  
    setResults([])
    setRec('')
    if (!prof_affiliate) {
      setCurretnPro(null)
      setCurrentCategory(null)
    }
    if (!localStorage.getItem('AuthToken')) {
      router.push('/home')
    }

    if (!liveActions.includes('me') && !isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
    }

   

  });

  useEffect(() => {
    if (prof_affiliate) {
      setCurrentCategory(categories.find(c => c.id == prof_affiliate.category_id))
      setCurretnPro(prof_affiliate)
    }

  }, [prof_affiliate])

  useEffect(() => {
    if(user && user.rec_count >= 3) {
      setSent(true);
    }
  }, [user])

  useEffect(() => {
    if(currentCategory) {
      setTimeout(() => {
        searchInputRef.current?.setFocus()
      }, 100);
    }
  }, [currentCategory])


  const handleSubmit = (e) => {
    
    window?.dataLayer?.push({
      event: "GTM event to GA",
      GA_event_category: "Doctorita",
      GA_event_action: "Doctorita_save_rec",
      GA_event_label: 'Doctorita_rec_for_profId_' + currentPro.objectID.split('_')[1]
    });
   
    let params = {
      professional_id: currentPro.objectID.split('_')[1],// this.doctor.id,
      rec_description: rec,
      source: localStorage.getItem('source'),
      category_id: currentCategory?.id
    }
    setSent(true);
    dispatch(saveRec(params))
    
    
      setResults([])
      setRec('')
      setCurretnPro(null)
      setCurrentCategory(null)
     
      }



  const search = (e) => {
    setSearchText(e.target.value)
    const client = algoliasearch('4BVKP3AWD6', '38e6fbc9f3a3ee8fb879805acdedf91f');
   // const client = algoliasearch('011DN3W1RP', '0fa406313af0e2f1ec51f8a4479cc3f3');
    let val = e.target.value.split(' ')
    if(val.length > 1 && ["דר", "'דר", 'ד"ר', 'דוקטור', 'פרופ', "פרופ'", "פרופסור", "מר", "גברת", "גב'"].includes(val[0])) {
      val.splice(0, 1)
    }
    const index = client.initIndex('doctorita');
    index.search(val.join(' '), { 
      filters: 'category_id:' + currentCategory.id, 
      hitsPerPage: 200,
      sortFacetValuesBy: 'alpha',
      queryLanguages: 'he',
    })
      .then((response) => {
        setResults(response.hits)
        if(val.join().length > 2 && response.hits.length == 0) {
          index.search(val.join(' '), { 
            hitsPerPage: 200,
            sortFacetValuesBy: 'alpha',
            queryLanguages: 'he',
          }).then((res) => {
            setResults(res.hits)
          })
        }
      })
  }

  const selectPro = (r) => {

    setCurretnPro(r);
    setResults([])
    window?.dataLayer?.push({
      event: "GTM event to GA",
      GA_event_category: "Doctorita",
      GA_event_action: "Doctorita_select_prof",
      GA_event_label: 'Doctorita_select_prof_profId_' + r.objectID.split('_')[1]
    });
   
  }

  
  const texts = [
    'באיזה תחום יש לך המלצה בשבילנו?'
  ]


const [activeIndex, setActiveIndex] = useState(0);

const [currentTextIndex, setCurrentTextIndex] = useState(0);
const [currentText, setCurrentText] = useState(" ");
const typingSpeed = 80; // in milliseconds
const delay = 2000; // in milliseconds

useEffect(() => {

const interval = setInterval(() => {
  typeText();
}, typingSpeed);

return () => clearInterval(interval);
}, [currentText]);

function typeText() {
const text = texts[currentTextIndex];
if (currentText.length < text.length) {
  setCurrentText(text.substring(0, currentText.length + 1));
  
} else {
 /*   setTimeout(() => {
        setCurrentTextIndex(currentTextIndex == texts.length - 1 ? 0 : currentTextIndex + 1);
        setCurrentText('');
      
     }, 50);     
   */ 
}
}

const RecNumber = ()  => {
  if(user?.rec_count == 0) {
    return 'ראשונה';
  }
  if(user?.rec_count == 1) {
    return 'שניה';
  }
  return 'שלישית'
}

const SubmitNewProf = () => {
  if(!searchText || !profMobile) {
    return;
  } 

  window?.dataLayer?.push({
    event: "GTM event to GA",
    GA_event_category: "Doctorita",
    GA_event_action: "Doctorita_submit_new_prof",
    GA_event_label: 'Doctorita_submit_new_prof_phone_' + profMobile
  });
 

  axios.post(`${API_URL}member/new-prof`, {
      name : searchText, mobile: profMobile, category_id : currentCategory.id
    }).then((res) => {
      setSearchText(null);
      setProfMobile(null);
      if(res && res.data?.data) {
        setCurretnPro(res.data?.data);
      } else {
        //
      }
  })
}

  const searchPlaceHolder = [1, 2, 3, 8].includes(currentCategory?.id) ? 'שם מטפל.ת / מומחיות' : 'שם רופא .ה / מומחיות'

  if(sent) {
    return (
      <IonPage id="home-page">
        <Helmet>
          <title>{user?.rec_count < 3 ? 'דוקטוריטה -  עמוד מעבר': 'דוקטוריטה - סיום המלצות'}</title>
        </Helmet>
  
        <Thank nextFunc={() => setSent(false)}/>
        </IonPage>
    )
 }

  return (
    <IonPage id="home-page">

        <Helmet>
          <title>{currentCategory ? ('דוקטוריטה - ' + currentCategory.name) : 'דוקטוריטה - קטגוריות' }</title>
        </Helmet>
      <IonContent fullscreen>
        {!currentCategory ? <img src={logo} className='logo' alt='logo' /> : ''}

        {!currentCategory ?
          <IonRow className={`stepper-row ${currentCategory ? "stepper-re" : ""}`}>
            <IonCol className='stepper-col' size='9'>
              <Stepper activeStep={user ? user.rec_count : 0}>
                {[1, 2, 3].map((label, index) => <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>)}
              </Stepper>
            </IonCol>
            <IonCol className='flag-col' size='3'>
              <span className='flag-line'></span>
              <img src={flag} alt="flag" className='flag-img' />
            </IonCol>
          </IonRow>
          : <></>
        }

        <IonRow>
          <IonCol>
            {!currentCategory
              ? <IonText className='text-center choose-cat'>
                {currentText}
              </IonText>
              : <>
                <IonIcon style={{ fontSize: '25px', position: 'absolute', right: '10%', marginTop: '35px' }} icon={chevronForwardOutline} onClick={() => {
                  setCurrentCategory(null)
                  setResults([]);
                  setSearchText(null);
                }} />
                <div className='category-title' style={{ width: '100%', textAlign: 'center' }}>
                  {currentCategory.name} </div>
              </>
            }
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {currentCategory ? <>
              <IonSearchbar type="text" debounce="500" onIonInput={search} placeholder={searchPlaceHolder}
                searchIcon={searchSharp} enterkeyhint="search" inputmode="search" ref={searchInputRef}   />

              {results && results.length > 0 &&
                <IonList class="searchRes" lines="full">
                  {results.map((r, i) => (<IonItem key={i} onClick={() => selectPro(r)} lines="none">
                    <div className="itemTitle">
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: r._highlightResult.name.value }}></div>
                        <div className="cat">{r.category}</div>
                        {!!r.city && <div className="cat">{r.city}</div>}

                      </div>
                      <div>
                        <IonIcon icon={personOutline} ></IonIcon>
                      </div>
                    </div>
                  </IonItem>))}
                </IonList>
              }

              {currentCategory.id == 8 && (!results || results.length == 0) && !!searchText && searchText.length > 4 &&
              <div >
                <br/>
                <IonRow>
                    <IonCol>
                        <IonInput className='tel-input' type="tel" value={profMobile} onIonInput={(event) => setProfMobile(event.target.value)}
                        inputmode="tel" required maxlength="10" pattern="[0][0-9]" placeholder='טלפון'
                       >
                        </IonInput>
                    </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol>
                 <p className='login-desc'>
                  לא מצאנו את <b>{searchText}</b> במאגר
                  <br/>
               אפשר להוסיף את הטלפון ולהמליץ
                </p>
                </IonCol>
                </IonRow>

                    <IonRow>
                      <IonCol>
                        <IonButton className='rec-btn next-btn' onClick={SubmitNewProf}>
                              שליחה
                      </IonButton>   
                    </IonCol>
                    </IonRow>
              </div>
              }
            </> :
              <IonGrid>
                {categories.map((cat, i) => (
                  <IonCard className={'cat-card'}  
                  /*style={{ width: cat.id == 8 ? '93%' : '44%', maxWidth: cat.id == 8 ? '93%' : 'unset' }}*/
                   key={cat.id} onClick={() => {
                    setCurrentCategory(cat);
                    setResults([]);
                    setSearchText(null)
                    window?.dataLayer?.push({
                      event: "GTM event to GA",
                      GA_event_category: "Doctorita",
                      GA_event_action: "Doctorita_select_category",
                      GA_event_label: 'Doctorita_select_category_' + cat.name
                    });
                  }}> {cat.name}</IonCard>
                ))}
              </IonGrid>
            }

          </IonCol>
        </IonRow>

        {!!currentPro &&
          <IonModal isOpen={!!currentPro}>
            <IonHeader className='ion-no-border'>
              <IonToolbar className='likePage'>
                <IonButtons slot="start">
                  <IonButton style={{ marginTop: '15px' }} onClick={() => {
                    setCurretnPro(null); 
                    setSearchText(null);
                    }}><IonIcon style={{ color: 'black' }} icon={chevronForwardOutline} /></IonButton>
                </IonButtons>
              </IonToolbar>
              {/* <IonTitle className='text-center'>
                <img width="35px" src={love_selected} />
              </IonTitle> */}
            </IonHeader>
            <IonContent className="ion-padding">

              <IonRow className='heart-img'>
                <img src={heart} alt='heart' />
              </IonRow>

              <IonRow>
                <IonCol className='ion-text-center'>
                  <div className="drName">{currentPro.name}</div>
                  <div className="cat">{currentPro.category}</div>
                  {!!currentPro.city && <div className="cat">{currentPro.city}</div>}

                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonTextarea no-lines className='recommTextarea' placeholder='חברים שלך ישמחו לקרוא כמה מילים, אבל לא חובה :)'
                    onIonInput={(e) => { setRec(e.target.value) }}></IonTextarea>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonButton className='rec-btn next-btn' onClick={handleSubmit}>
                    שליחת המלצה <RecNumber />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonContent>
          </IonModal>}

      </IonContent>
    </IonPage>
  );
};

export default Recommendations;
