import React from 'react';
import {
  IonButton,
  IonCol,
  IonIcon,
  IonRow
} from '@ionic/react';
import './Step.css'
import { Link } from 'react-router-dom';
import './Step.css'
import step3 from '../assets/imgs/step3.svg';
import MobileStepper from '@mui/material/MobileStepper';
import { chevronBackOutline } from 'ionicons/icons';



const Step3 = () => {
  return (
    <div className="step-content">
      <img src={step3} alt='step 3' className='step-img' />
      <IonRow>
        <IonCol>
          <MobileStepper
            className='stepper'
            variant="dots"
            steps={3}
            position="static"
            activeStep={2} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h3 className='title'>יאללה, אני בעניין!</h3>
          <p className='desc'>
            הירשמו עכשיו ותנו 3 המלצות שלכם על רופאים שאשכרה הצילו לכם את החיים!
          </p>
        </IonCol>
      </IonRow>
      <IonRow className='next-btn-row' style={{marginTop : window.innerHeight < 750 ? '20px' : '50px'}}>
        <IonCol>
          <Link to={'/login'}><IonButton className='next-btn' style={{ width: '85%' }} shape="round">
            אני רוצה כבר להירשם    <IonIcon icon={chevronBackOutline} slot="end" />
          </IonButton></Link>

        </IonCol>
      </IonRow>

    </div>);
};

export default Step3;
