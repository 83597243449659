import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonInput,
  IonPage,
  IonButton,
  IonCol,
  IonRow,
  useIonRouter,
  IonCheckbox,
  IonLoading,
  IonModal,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonIcon
} from '@ionic/react';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { login, restartOtp, sendOtp } from '../redux/actions/authActions';
import logo from '../assets/imgs/logo.svg'
import { closeOutline } from 'ionicons/icons';
import { useLocation } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";
import {Helmet} from "react-helmet";

const Login = () => {
  const router = useIonRouter()
  const location = useLocation()
  const { user, isAuthenticated, otp, prof_affiliate, settings } = useSelector((state = {}) => state.auth);
  const { loading } = useSelector((state = {}) => state.async);
  const [token, setToken] = useState(null);
 
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState(null);
  const [is_policy, setIsPolicy] = useState(false);
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [isPOpen, setIsPOpen] = useState(false);

  const recaptchaRef = React.useRef();
  const phoneRef = React.useRef()
  const codeRef = React.useRef()

  
  useEffect(() => {
    if(!otp || otp === 'fresh') {
      phoneRef.current?.setFocus()
    } else {
      setTimeout(() => {
        codeRef.current?.setFocus()
      }, 150)
    }
  
  }, [otp])

  useEffect(() => {
   
      setTimeout(() => {
        phoneRef.current.setFocus()
      }, 150)
    
  
  }, [])

  useEffect(() => {
    if(location.pathname == '/login') {
      
      if (isAuthenticated && (!user || user?.rec_count < 3)) {
        router.push(user?.last_name ? '/recommendations' : '/name')
      } else if(isAuthenticated && user?.rec_count >= 3) {
        router.push('/recommendations')
      }
  }
  }, [isAuthenticated,user])

/*  useEffect(() => {
    try {
      if ("OTPCredential" in window) {
        const ac = new AbortController();
        ac.abort();

        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            console.log(otp)
            setCode(otp.code);
            handleSubmit()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (e) {
      console.log(e)
    }
  }, []);
*/



  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!otp || otp == 'fresh') {
      window?.dataLayer?.push({
        event: "GTM event to GA",
        GA_event_category: "Doctorita",
        GA_event_action: "Doctorita_click_register",
        GA_event_label: 'Doctorita_click_register'
      });
      
      //recaptchaRef.current.executeAsync().then(dtoken => {
        if (!mobile) {
          return;
        }
        dispatch(sendOtp({
          mobile: mobile,
         // recaptcha: dtoken, 
        }));
    //  })
    } else {
      if (isAuthenticated) {
        router.push('/name')
        return
      }
      window?.dataLayer?.push({
        event: "GTM event to GA",
        GA_event_category: "Doctorita",
        GA_event_action: "Doctorita_click_login",
        GA_event_label: 'Doctorita_click_login'
      });
      dispatch(login({ mobile: mobile, verification_code: code }));
    }
  };

  const reSendCode = () => {
    dispatch(restartOtp())
  }


  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && ((otp && otp != 'fresh') ? (is_policy && code && code.length >= 4) : (mobile && mobile.length == 10) )) {
      handleSubmit()
    }
  }

  const CaptchaChange = (token) => {
    setToken(token);
   
  };

  return (
    <IonPage id="login-page">
       <Helmet>
          <title>{!otp || otp === 'fresh' ? 'דוקטוריטה - הרשמה' : 'דוקטוריטה - אימות'}</title>
      </Helmet>
      <IonContent fullscreen>
        <img src={logo} className='logo' alt='logo' />

        <IonRow>
          {!otp || otp === 'fresh' ?
            <IonCol>
              {/*<p className='connexionTitle'>
                כמה פרטים לפני שנתחיל
                {!!prof_affiliate && <sub><br />
                  להמליץ על {prof_affiliate.name}
                </sub>}
              </p>*/}
                <p className='first-desc'>
                זה הרגע לפרגן
                <br/>
                ל-3 רופאים/מטפלים וגם לעצמך....
                </p>

              <p className='login-desc'>שלחו לי קוד כניסה חד-פעמי למספר הטלפון שלי</p>
            </IonCol>
            :
            <IonCol>
              <p className='connexionTitle sms-text'>
                מה הקוד שקיבלת בסמס?
              </p>
          <br />
              <span>לא קיבלת את הקוד?</span>
              <a className='resend-btn' onClick={reSendCode}> שלח/י שוב</a>
          
            </IonCol>
          }
        </IonRow>

        <IonRow>
          <IonCol>
            {!otp || otp === 'fresh' ?
              <div>
                <IonInput ref={phoneRef} className='tel-input' type="tel" value={mobile} onIonInput={(event) => setMobile(event.target.value)}
                inputmode="tel" required maxlength="10" pattern="[0][0-9]" placeholder='טלפון' 
                onKeyUp={handleKeyUp}>
              </IonInput>
         
              {/*<ReCAPTCHA
                  size="invisible"
                    ref={recaptchaRef}
                    className="captcha-st"
                    sitekey={process.env.CAPTCHA_SITE_KEY || '6LcrBX0nAAAAAIFi7kTukrVV0zFZAdtxK56x010M'} 
                    onChange={CaptchaChange}
                  />*/}
                </div>
              : 
                  <IonInput className='sms-input' type="tel" value={code} onIonInput={(event) => setCode(event.target.value)}
                  inputmode="tel" required maxlength="6" pattern="[0-9]"  ref={codeRef}
                  onKeyUp={handleKeyUp}>
                </IonInput>
            }
          </IonCol>
        </IonRow>
        {otp && otp !== 'fresh' && <div className='checkboxes' style={{ maxWidth: '80%', margin: '0 auto' }}>
          <IonRow>
          <IonCol size="2"></IonCol>
            <IonCol size="1" className='confirmMail-cbx'>
              <IonCheckbox labelPlacement="end" onIonChange={() => setIsPolicy(!is_policy)} checked={is_policy}></IonCheckbox>
            </IonCol>
            <IonCol className='policy-text'>
            <span onClick={() => setIsPolicy(!is_policy)}>קראתי ואני מסכימ.ה</span>
            <a onClick={() => setIsPOpen(true)}> למדיניות הפרטיות </a>       
            <a onClick={() => setIsOpen(true)}> ולתנאי השימוש </a>
            <span onClick={() => setIsPolicy(!is_policy)}>

            באתר .
          </span>   
              {/*<span onClick={() => setIsPolicy(!is_policy)}>
                אני מאשר/ת את  
                </span>
                <a onClick={() => setIsOpen(true)}> תנאי השימוש </a>,   
                <a onClick={() => setIsPOpen(true)}> מדיניות הפרטיות </a><br />
                <span onClick={() => setIsPolicy(!is_policy)}>
                    וקבלת תכנים פרסומיים מהאתר
          </span>*/}
            </IonCol>
          </IonRow>
        </div>}

        <IonRow>
          <IonCol>
            {((otp && otp != 'fresh') ? (is_policy && code && code.length >= 4) : (mobile && mobile.length == 10))  &&
            <IonButton disabled={loading}  onClick={handleSubmit} className='next-btn send-btn' shape="round" style={{ width: '80%' }}>
              { (otp && otp != 'fresh') ? 'שליחה' : 'אישור'}


            </IonButton>}
            <IonLoading isOpen={loading} />

          </IonCol>
        </IonRow>

        <IonModal isOpen={isOpen}>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>    <IonIcon icon={closeOutline} ></IonIcon>
                    </IonButton>
              </IonButtons>
            </IonToolbar>
          <IonContent className="ion-padding">
          <h3 style={{textAlign: 'center'}}>{settings?.find(s => s.Slug == 'terms-of-use')?.Name}</h3>

          <div style={{textAlign: 'justify', padding: '10px', fontFamily: 'auto'}}
           dangerouslySetInnerHTML={{ __html: settings?.find(s => s.Slug == 'terms-of-use')?.Value }}></div>
                       
          </IonContent>
        </IonModal>

        <IonModal isOpen={isPOpen}>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsPOpen(false)}>    <IonIcon icon={closeOutline} ></IonIcon>
                    </IonButton>
              </IonButtons>
            </IonToolbar>
          <IonContent className="ion-padding">
          <h3 style={{textAlign: 'center'}}>{settings?.find(s => s.Slug == 'privacy')?.Name}</h3>

          <div style={{textAlign: 'justify', padding: '10px', fontFamily: 'auto'}}
          dangerouslySetInnerHTML={{ __html: settings?.find(s => s.Slug == 'privacy')?.Value }}></div>
                       
          </IonContent>
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default Login;
