// Async
export const ASYNC_ACTION_START = 'ASYNC_ACTION_START'
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH'
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR'
export const ASYNC_ACTION_CLEAN_ERROR = 'ASYNC_ACTION_CLEAN_ERROR'

// User
export const OTP='OTP'
export const RESTART_OTP='RESTART_OTP'
export const LOGIN = 'LOGIN'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const RESET_FORGOT_FLOW = 'RESET_FORGOT_FLOW'
export const CLEAN_REGISTRATION_SUCCESS = 'CLEAN_REGISTRATION_SUCCESS'
export const SET_AS_AUTHENTICATED = 'SET_AS_AUTHENTICATED'
export const SET_PROFILE_ITEMS = 'SET_PROFILE_ITEMS'
export const USER_DATA = 'USER_DATA'
export const PROF_AFFILIATE = 'PROF_AFFILIATE'
export const SETTINGS = 'SETTINGS'


// Navbar
export const SET_NAVBAR_CURRENT_ITEM = 'SET_NAVBAR_CURRENT_ITEM'
export const SET_EXPPANDED_NAV = 'SET_EXPPANDED_NAV'
export const SET_ITEMS = 'SET_ITEMS'

// Modal
export const OPEN_MODAL = 'OPEN_LOGIN_MODAL'
export const CLOSE_MODAL = 'CLOSE_LOGIN_MODAL'

