import React from 'react';
import {
  IonButton,
  IonCol,
  IonRow
} from '@ionic/react';
import './Step.css'
import doc from '../assets/imgs/doc.png';
import MobileStepper from '@mui/material/MobileStepper';

interface StepProps {
  next?: (e: any) => void,
}


const Step1 = ({ next }: StepProps) => {

  return (
    <div className="step-content">
      <img src={doc} alt='step 1' className='step-img max-260' />
      {/*<IonRow>
        <IonCol>
          <MobileStepper
            className='stepper'
            variant="dots"
            steps={3}
            position="static"
            activeStep={0} />
        </IonCol>
  </IonRow>*/}
      <IonRow>
        <IonCol>
          <h3 className='title'>
           בא לך להמליץ ולצאת לחופשה?
            </h3>
          {/*<p className='desc'>
          זה הזמן להמליץ על 3 רופאים/מטפלים
           <br/>
           ולהשתתף במשחק עם פרס לחופשה מפנקת במלון שווה
</p>*/}

          <p className='desc'>קליק על הלינק, המלצה על 3 רופאים/מטפלים 
            ויש לך הזדמנות לחופשה מפנקת
          </p>
        </IonCol>
      </IonRow>
      <IonRow className='next-btn-row'>
        {/* <IonCol>
          <IonButton color="dark" className='jump-btn' fill="clear" onClick={() => next()}>
            דלג
          </IonButton>
        </IonCol> */}
        <IonCol>
          <IonButton className='next-btn' shape="round" onClick={() => next()}>
          אני בעניין!
          </IonButton>
        </IonCol>
      </IonRow>

    </div>);
};

export default Step1;

