import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_AS_AUTHENTICATED,
  CLEAN_REGISTRATION_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_FORGOT_FLOW,
  SET_PROFILE_ITEMS,
  USER_DATA,
  OTP,
  RESTART_OTP,
  PROF_AFFILIATE,
  SETTINGS
} from "../actions/types";

const initialState = {
  user: undefined,
  AuthToken: undefined,
  refreshToken: undefined,
  isAuthenticated: false,
  forgotPasswordSuccess: false,
  registerSuccess: false,
  Name: undefined,
  otp: false,
  prof_affiliate: null,
  settings: null
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AS_AUTHENTICATED:
      localStorage.setItem("AuthToken", payload);
      return {
        ...state,
        AuthToken: payload, // puts token in state
        isAuthenticated: true,
      };
    case SET_PROFILE_ITEMS:
      return {
        ...state,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
      };
    case OTP:
      return {
        ...state,
        otp: true,
      };
    case RESTART_OTP:
      return {
        ...state,
        otp: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("AuthToken", payload.access_token);
      localStorage.setItem("FirstName", payload.FirstName);
      localStorage.setItem("LastName", payload.LastName);
      return {
        ...state,
        AuthToken: payload.access_token, // puts token in state
        isAuthenticated: true,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
        user: payload,
      };
    case LOGIN_FAIL:
      return {
        ...state, 
        otp: 'fresh'
      }
    case REGISTER_FAIL:
    case FORGOT_PASSWORD_FAIL:   
    case LOGOUT:
      localStorage.removeItem("AuthToken");
      localStorage.removeItem("Name");
      return {
        ...state,
        AuthToken: null,
        isAuthenticated: false,
        user: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      localStorage.removeItem("AuthToken");
      return {
        ...state,
        AuthToken: null,
        isAuthenticated: false,
        forgotPasswordSuccess: true,
        user: null,
      };
    case RESET_FORGOT_FLOW:
      return {
        ...state,
        forgotPasswordSuccess: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registerSuccess: true,
      };
    case CLEAN_REGISTRATION_SUCCESS:
      return {
        ...state,
        registerSuccess: false,
      };
    case USER_DATA:
      return {
        ...state,
        user: payload,
      };
    case PROF_AFFILIATE:
      return {
        ...state,
        prof_affiliate: payload
      }
    case SETTINGS:
      return {
        ...state,
        settings: payload
      }
    default:
      return {
        ...state,
        //  AuthToken: localStorage.getItem('AuthToken'), // puts token in state
        FirstName: localStorage.getItem("FirstName"),
        LastName: localStorage.getItem("LastName"),
      };
  }
};

export default authReducer;
