import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
} from '@ionic/react';
import './Home.css';
import logo from '../assets/imgs/logo.svg'
import Step3 from '../components/Step3';

const Step3Page = (props) => {

  const refresh = (e) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <img src={logo} className='logo' alt='logo' />

        <Step3 />
        
      </IonContent>
    </IonPage>
  );
};

export default Step3Page;
