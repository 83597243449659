import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { getMeDetails, setAsAuthenticated } from '../redux/actions/authActions';
import { useLocation, useParams } from 'react-router';
import logo from '../assets/imgs/logo.svg'
import FirstPageImg from '../assets/imgs/first_page.svg'

import './FirstPage.css'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const FirstPage = (props) => {
  const { user, isAuthenticated } = useSelector((state = {}) => state.auth);


  return (
    <IonPage id="home-page">
      <Helmet>
          <title>דוקטוריטה - תיאור לאחר הרשמה</title>
      </Helmet>
      <IonContent fullscreen>
        <img src={logo} className='logo' alt='logo' />

        <IonRow>
          <IonCol>
            {/*<h3 className='first-title'>חוק הקארמה הראשון</h3>
            <p className='first-desc'>בשביל <span className='purpule'>לקבל</span> המלצות טובות<br />
              צריך <span className='purpule'>לתת</span> המלצות טובות
            </p>
            <p className='sub-desc'>
              כל מה שצריך לעשות זה לרשום 3 המלצות על רופאים שאתם אוהבים.
  </p>*/}
          <p className='first-desc'>
          זה הרגע לפרגן
          <br/>
          ל-3 רופאים/מטפלים וגם לעצמך....
          </p>

          <p className='sub-desc'>
          כל מה שנותר, זה רק למלא 3 המלצות אישיות
          ולהיכנס למשחק עם פרס לחופשה!

          </p>

          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <img src={FirstPageImg} className='max-260' />
          </IonCol>
        </IonRow>


        <IonRow className='next-btn-row' style={{marginTop : window.innerHeight < 1000 ? '10px' : '40px'}}>
          <IonCol>
            <Link id="to-recommendations" to={'/recommendations'}><IonButton className='next-btn' style={{ width: '85%' }} shape="round">
            בואו נתחיל
            </IonButton></Link>
          </IonCol>
        </IonRow>


      </IonContent>
    </IonPage>
  );
};

export default FirstPage;
