import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonViewWillEnter
} from '@ionic/react';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from '../redux/actions/authActions';
import logo from '../assets/imgs/logo.svg'

const Privacy = (props) => {
  const { settings } = useSelector((state = {}) => state.auth);
  const dispatch = useDispatch()

  useIonViewWillEnter(() => {
    dispatch(getSettings())    
  });

  const refresh = (e) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <img src={logo} className='logo' alt='logo' />
        <h3>{settings?.find(s => s.Slug == 'privacy')?.Name}</h3>

        <div style={{textAlign: 'justify', padding: '10px', fontFamily: 'auto'}} 
        dangerouslySetInnerHTML={{ __html: settings?.find(s => s.Slug == 'privacy')?.Value }}></div>
   
      </IonContent>
    </IonPage>
  );
};

export default Privacy;
